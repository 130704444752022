/*color*/
$primary: #ff343f;
$secondary: #383a3e;
$white: #fff;
$gray_light: #dedede;
$gray: #bbb;
$black: #111;
$general: #a2a2a2;
$black_soft: #333;
$black_col: #2e333c;
$black_more: #0f0f0f;
$color: #ff343f;
$default_background: #212428;
$warning_dark: #ff9800; 
$warning_light: #ffeb3b;
$color_more: #fc202c;
$light_theme_primary : #8364e2;
$light_theme_secondary : #D9D9E6;

// radius
$borderRadius: 8px;
